import React, { Component } from 'react'
import Layout from '../../components/layout'
import { withContext } from '../../contexts/withContext'

class TestPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount(){
    // console.log(this.props.context)
  }

  render() {
    return (
      <Layout>
        <div>test</div>
      </Layout>
    )
  }
}

export default withContext(TestPage)
