import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import routes from './routes'
import './App.css'
import Provider from './contexts/provider'

class App extends Component {
  render() {
    return (
      <Provider>
        {routes}
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
      </Provider>
    )
  }
}

export default App
