import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Layout from '../../components/layout'
import { withContext } from '../../contexts/withContext'

class Home extends Component {
  render() {
    const { userData } = this.props.context
    return (
      <Layout>
        <div className="d-flex flex-column align-items-center justify-content-around mainmenu">
          {userData ? null : (
            <Link to="/signup">
              <button type="button" className="btn btn-primary">
                Sign Up
                <br />
                สมัครสมาชิก
              </button>
            </Link>
          )}
          {userData ? null : (
            <Link to="/login">
              <button type="button" className="btn btn-primary">
                Login <br />
                เข้าสู่ระบบ
              </button>
            </Link>
          )}
          {userData ? (
            <Link to="/reimbursement">
              <button type="button" className="btn btn-primary">
                New reimbursement
                <br />
                สร้างรายการเบิก
              </button>
            </Link>
          ) : null}
          {userData ? (
            <Link to="/user-reimbursements">
              <button type="button" className="btn btn-primary">
                My reimbursements
                <br />
                รายการเบิกของฉัน
              </button>
            </Link>
          ) : null}
          {/* #conroles */}
          {userData && (userData.role === 1 || userData.role === 2) ? (
            <Link to="/waiting-reimbursements">
              <button type="button" className="btn btn-primary">
                Waiting for approval
                <br />
                รอการอนุมัติ
              </button>
            </Link>
          ) : null}
          {userData && userData.role === 2 ? (
            <Link to="/reimbursements?days=30">
              <button type="button" className="btn btn-primary">
                All transactions
                <br />
                รายการทั้งหมด
              </button>
            </Link>
          ) : null}
          {userData && userData.role === 2 ? (
            <Link to="/particulars?days=30">
              <button type="button" className="btn btn-primary">
                All particulars
                <br />
                รายการทั้งหมด
              </button>
            </Link>
          ) : null}
        </div>
      </Layout>
    )
  }
}

export default withContext(Home)
