import React, { Component } from 'react'
import Swal from 'sweetalert2'
import moment from 'moment'
import 'react-table/react-table.css'
import { toast } from 'react-toastify'

import Layout from '../../components/layout'
import { withContext } from '../../contexts/withContext'
import { CONST } from '../../constants'
import ReactTableSaveWidth from '../../components/ReactTableSaveWidth'
import { downloadCSV, formatReimstatus, formatRunningNumber, getDays } from '../../utils/utilFunctions'
import DayFilter from '../../components/dayFilter'

class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      particularsList: [],
      dataFetched: false,
    }
  }

  async componentDidMount() {
    const res = await this.props.context.fetch('get', `/api/particulars/?days=${getDays()}`)
    if (res.success) {
      console.log(res.data)
      this.setState({ particularsList: res.data, dataFetched: true })
    } else {
      toast.error('Fail to fetch particular list')
    }
  }

  onDownloadCSV = () => {
    const data = this.state.particularsList.map(p => {
      return {
        running_number: formatRunningNumber(p.reimbursement_running_number),
        name: `${p.name}`,
        category: `${p.category_name}`,
        amount: `${p.amount}`,
      }
    })
    downloadCSV(data, 'particulars.csv')
  }

  render() {
    const { particularsList, dataFetched } = this.state
    const { userData } = this.props.context

    if (dataFetched === false) {
      return (
        <Layout>
          <div>Fetching data...</div>
        </Layout>
      )
    }
    return (
      <Layout>
        <ReactTableSaveWidth
          storeName="particulars"
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (rowInfo) {
                  this.props.history.push(`/reimbursement/${rowInfo.original.reimbursement}?filter=all`)
                }
                if (handleOriginal) {
                  handleOriginal()
                }
              },
            }
          }}
          data={particularsList}
          columns={[
            {
              Header: 'Running Number',
              id: 'runningNumber',
              accessor: d => formatRunningNumber(d.reimbursement_running_number),
            },
            {
              Header: 'Name',
              id: 'name',
              accessor: d => d.name,
            },
            {
              Header: 'Category',
              id: 'category',
              accessor: d => d.category_name,
            },
            {
              Header: 'Amount',
              id: 'amount',
              accessor: d => d.amount,
            },
          ]}
          className="-striped -highlight"
          showPagination={true}
        />
        <button className="btn" type="button" onClick={this.onDownloadCSV}>
          Download CSV
        </button>
        <DayFilter />
      </Layout>
    )
  }
}

export default withContext(LoginPage)
