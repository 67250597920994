import React, { Component } from 'react'
import TetheredSelect from '../components/tetheredSelectWrap'
import Select from 'react-select'
import _ from 'lodash'
import Cleave from 'cleave.js/react'

class FormInput extends Component {
  constructor(props) {
    super(props)
    this.input = React.createRef()
  }

  // componentDidMount() {
  //   const { choices, onChange, value } = this.props
  //   if (choices && choices.length > 0 && (value === undefined || value === '' )) {
  //     // console.log('oc didmount', choices[0].value)
  //     onChange(choices[0].value)
  //   }
  // }

  // componentDidUpdate(prevProps) {
  //   if ((this.props.choices && this.props.choices.length) !== (prevProps.choices && prevProps.choices.length)) {
  //     // console.log('oc update')
  //     this.props.onChange(this.props.choices.length > 0 ? this.props.choices[0].value : undefined)
  //   }
  // }

  render() {
    const { fieldName, value, errors, type, choices, className, onChange, accept, cleaveJsOptions } = this.props
    return (
      <div className={`form-group ${className || ''}`}>
        {_.isEmpty(fieldName) ? null : <label>{fieldName}</label>}
        <div>
          {choices ? (
            <Select
              value={choices.find(c => c.value === value)}
              onChange={v => onChange(v.value)}
              options={choices}
              placeholder="Select..(type to search)"
            />
          ) : _.isEmpty(cleaveJsOptions) ? (
            <input
              ref={this.input}
              type={type || 'text'}
              value={value}
              onChange={e => {
                onChange(e.currentTarget.value)
              }}
              className={`form-control ${errors ? 'is-invalid' : ''}`}
              accept={accept}
            />
          ) : (
            <Cleave
              ref={this.input}
              type={type || 'text'}
              value={value}
              onChange={e => {
                onChange(e.currentTarget.value)
              }}
              className={`form-control ${errors ? 'is-invalid' : ''}`}
              options={cleaveJsOptions}
              accept={accept}
            />
          )}
        </div>
        {errors && _.isArray(errors)
          ? errors.map(e => (
              <div key={e} className="text-danger">
                <small>{e}</small>
              </div>
            ))
          : null}
      </div>
    )
  }
}

export default FormInput
