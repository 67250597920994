import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { toast } from 'react-toastify'
import Layout from '../../components/layout'
import { withContext } from '../../contexts/withContext'
import FormInput from '../../components/formInput'

class EditProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: {
        email: '',
        password: '',
        repassword: '',
        first_name: '',
        last_name: '',
      },
      errors: {},
    }
  }

  async componentDidMount() {
    const userRes = await this.props.context.fetch('get', `/api/users/`)
    if (userRes.success) {
      this.setState({ fields: { ...userRes.data[0], password: '', repassword: '' } })
    } else {
      toast.error('Fail to fetch user data')
    }
  }

  submit = async () => {
    let { id, ...fieldValues } = this.state.fields
    fieldValues = _.pickBy(fieldValues, v => v !== '')
    // console.log(fieldValues)
    const res = await this.props.context.fetch('put', `/api/users/${id}/`, fieldValues)
    if (res.success) {
      // this.props.
      toast.success('User successfully updated')
    } else {
      this.setState({ errors: res.data })
    }
  }

  render() {
    const { fields, errors } = this.state
    return (
      <Layout>
        <form>
          <FormInput
            fieldName="Email*"
            value={fields.email}
            errors={errors.email}
            type="text"
            onChange={v => this.setState({ fields: { ...fields, email: v } })}
          />
          <FormInput
            fieldName="Password"
            value={fields.password}
            errors={errors.password}
            type="password"
            onChange={v => this.setState({ fields: { ...fields, password: v } })}
          />
          <FormInput
            fieldName="Repeat password"
            value={fields.repassword}
            errors={errors.repassword}
            type="password"
            onChange={v => this.setState({ fields: { ...fields, repassword: v } })}
          />
          <FormInput
            fieldName="First name*"
            value={fields.first_name}
            errors={errors.first_name}
            type="text"
            onChange={v => this.setState({ fields: { ...fields, first_name: v } })}
          />
          <FormInput
            fieldName="Last name*"
            value={fields.last_name}
            errors={errors.last_name}
            type="text"
            onChange={v => this.setState({ fields: { ...fields, last_name: v } })}
          />
          <button className="btn btn-primary" type="button" onClick={this.submit}>
            Save
          </button>
        </form>
      </Layout>
    )
  }
}

export default withRouter(withContext(EditProfile))
