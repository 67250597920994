import React, { Component } from 'react'
import ReactTable from 'react-table'
import _ from 'lodash'

const STORE_SUFFIX = 'reacttable_'
class ReactTableSaveWidthSaveWidth extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columnWidths: {},
    }
  }

  componentWillMount() {
    this.setState({ columnWidths: this.getStoredWidth() })
  }

  getStoredWidth = () => {
    const storeWdith = localStorage.getItem(`${STORE_SUFFIX}${this.props.storeName}`)
    if (storeWdith) {
      try {
        return JSON.parse(storeWdith)
      } catch (err) {
        localStorage.removeItem(`${STORE_SUFFIX}${this.props.storeName}`)
      }
    }
    return {}
  }

  render() {
    return (
      <ReactTable
        pageSizeOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
        {...this.props}
        columns={this.props.columns.map(col => {
          return { ...col, width: this.state.columnWidths[col.id] }
        })}
        onResizedChange={_.debounce(newWidth => {
          {
            const storedWidth = this.getStoredWidth()
            newWidth.forEach(col => (storedWidth[col.id] = col.value))
            localStorage.setItem(`${STORE_SUFFIX}${this.props.storeName}`, JSON.stringify(storedWidth))
          }
        }, 500)}
      />
    )
  }
}

export default ReactTableSaveWidthSaveWidth
