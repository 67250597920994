import React, { Component } from 'react'

import Navbar from '../navbar'

class Layout extends Component {
  // TODO get 70px from navbar height
  render() {
    return (
      <div>
        <Navbar />
        <div className="p-3" style={{ height: 'calc(100vh - 70px)' }}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default Layout
