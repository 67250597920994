import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import Layout from '../../components/layout'
import { withContext } from '../../contexts/withContext'
import FormInput from '../../components/formInput'

class SignupPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: {
        email: '',
        password: '',
        repassword: '',
        first_name: '',
        last_name: '',
        phone_number: '',
      },
      errors: {},
    }
  }

  submit = async e => {
    e.preventDefault()
    const res = await this.props.context.fetch('post', '/api/users/', this.state.fields)
    if (res.success) {
      toast.success('Registration Successful', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
      this.props.history.push('/login')
    } else {
      this.setState({ errors: res.data })
    }
  }

  render() {
    const { fields, errors } = this.state
    return (
      <Layout>
        <form onSubmit={this.submit}>
          <FormInput
            fieldName="Email*"
            value={fields.email}
            errors={errors.email}
            type="text"
            onChange={v => this.setState({ fields: { ...fields, email: v } })}
          />
          <FormInput
            fieldName="Password*"
            value={fields.password}
            errors={errors.password}
            type="password"
            onChange={v => this.setState({ fields: { ...fields, password: v } })}
          />
          <FormInput
            fieldName="Repeat password*"
            value={fields.repassword}
            errors={errors.repassword}
            type="password"
            onChange={v => this.setState({ fields: { ...fields, repassword: v } })}
          />
          <FormInput
            fieldName="First name*"
            value={fields.first_name}
            errors={errors.first_name}
            type="text"
            onChange={v => this.setState({ fields: { ...fields, first_name: v } })}
          />
          <FormInput
            fieldName="Last name*"
            value={fields.last_name}
            errors={errors.last_name}
            type="text"
            onChange={v => this.setState({ fields: { ...fields, last_name: v } })}
          />
          <FormInput
            fieldName="Phone Number*"
            value={fields.phone_number}
            errors={errors.phone_number}
            type="text"
            onChange={v => this.setState({ fields: { ...fields, phone_number: v } })}
          />
          <button className="btn btn-primary" type="submit">
            Sign Up
          </button>
        </form>
      </Layout>
    )
  }
}

export default withRouter(withContext(SignupPage))
