import React from 'react'
import Dimensions from 'react-dimensions'
import Select from 'react-select'
import TetherComponent from 'react-tether'
    
class TetheredSelectWrap extends Select {
  constructor(props) {
    super(props)

    this.renderOuter = this._renderOuter
  }

  componentDidMount() {
    super.componentDidMount.call(this)
  }

  _renderOuter() {
    const { containerWidth } = this.props
    const menu = super.renderOuter.apply(this, arguments)

    // Don't return an updated menu render if we don't have one
    if (!menu) {
      return
    }

    return (
      <TetherComponent
        renderElementTo="body"
        ref="tethered-component"
        attachment="top left"
        targetAttachment="top left"
        constraints={[
          {
            to: 'window',
            attachment: 'together',
            pin: ['top'],
          },
        ]}
      >
        {/* Apply position:static to our menu so that it's parent will get the correct dimensions and we can tether the parent */}
        <div />
        {React.cloneElement(menu, { style: { position: 'static', width: containerWidth } })}
      </TetherComponent>
    )
  }
}

// Call the AsyncCreatable code from react-select with our extended tether class
class TetheredSelect extends React.Component {
  render() {
    return (
      <TetheredSelectWrap.Async {...this.props}>
        {asyncProps => (
          <TetheredSelectWrap.Creatable {...this.props}>
            {creatableProps => (
              <TetheredSelectWrap
                {...asyncProps}
                {...creatableProps}
                onInputChange={input => {
                  creatableProps.onInputChange(input)
                  return asyncProps.onInputChange(input)
                }}
              />
            )}
          </TetheredSelectWrap.Creatable>
        )}
      </TetheredSelectWrap.Async>
    )
  }
}

export default Dimensions()(TetheredSelect)
