import React, { Component } from 'react'
import { toast } from 'react-toastify'
import axios from '../utils/axiosWrapper'

export const Context = React.createContext()

class Provider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false,
      token: undefined,
      userData: undefined,
    }
  }

  componentDidMount = async () => {
    // console.log('pre fetch token')
    const tokenRes = await this.fetch('get', `/get-token/`)
    if (tokenRes.success) {
      this.setState({ token: tokenRes.data.token }, async () => {
        // console.log('pre fetch user')
        const userRes = await this.fetch('get', `/api/users/`)
        // console.log('post fetch user')
        if (userRes.success) {
          this.setState({ isLoaded: true, userData: userRes.success ? userRes.data[0] : undefined })
        } else {
          await this.logout()
          this.setState({ isLoaded: true })
        }
      })
    } else {
      this.setState({ isLoaded: true })
    }
    // this.setState({ isLoaded: true })

  }

  fetch = async (method, url, data, headers) => {
    const { token } = this.state
    // console.log(`fetch ${url}`, data)

    try {
      const response = await axios({
        method,
        url,
        data,
        headers: token ? { ...headers, Authorization: `JWT ${token}` } : headers,
      })
      // console.log('fetch res', response)
      return { data: response.data, success: true }
    } catch (err) {
              // TODO 401 log out (only if token exists)
          // TODO catch timeout error
      // console.error('fetch err', err.response && err.response.data ? err.response.data : err.response)
      const errData = err.response !== undefined ? err.response.data : undefined
      return { data: errData, success: false }
    }
      
    

    // return response
  }

  login = async (username, password) => {
    const tokenRes = await this.fetch('post', '/api-token-auth/', { username, password })
    if (tokenRes.success) {
      this.setState({ token: tokenRes.data.token }, async () => {
        const userRes = await this.fetch('get', `/api/users/`)
        if (userRes.success) {
          this.setState({ userData: userRes.data[0] })
        } else {
          console.error(userRes) // TODO error
        }
      })
      return { success: true }
    }
    return { success: false }
  }

  loginsecret = async (username) => {
    const tokenRes = await this.fetch('post', '/api-token-auth-secret/', { username })
    if (tokenRes.success) {
      console.log('token', tokenRes.data.token)
      this.setState({ token: tokenRes.data.token }, async () => {
        const userRes = await this.fetch('get', `/api/users/`)
        if (userRes.success) {
          this.setState({ userData: userRes.data[0] })
        } else {
          console.error(userRes) // TODO error
        }
      })
      return { success: true }
    }
    return { success: false }
  }

  logout = async () => {
    console.log('logout')
    const res = await this.fetch('post', `/logout`)
    if (res.success) {
      this.setState({ token: undefined, userData: undefined })
    } else {
      toast.error('logout failed')
    }
  }

  render() {
    const { children } = this.props
    const { userData, isLoaded } = this.state
    return (
      <Context.Provider
        value={{
          userData,
          isLoaded,
          login: this.login,
          loginsecret: this.loginsecret,
          logout: this.logout,
          fetch: this.fetch,
        }}
      >
        {isLoaded ? children : null}
      </Context.Provider>
    )
  }
}

export default Provider
