import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Home from '../pages/home'
import Login from '../pages/login'
import LoginSecret from '../pages/loginSecret'
import Test from '../pages/test'
import Signup from '../pages/signup'
import EditProfile from '../pages/editprofile'
import Reimbursement from '../pages/reimbursement'
import Reimbursements from '../pages/reimbursements'
import UserReimbursements from '../pages/userReimbursements'
import WaitingReimbursements from '../pages/waitingReimbursements'
import Particulars from '../pages/particulars'

//TODO prop types for page component
const routes = (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/login" component={Login} />
      <Route path="/login-secret-179230" component={LoginSecret} />
      <Route path="/signup" component={Signup} />
      <Route path="/editprofile" component={EditProfile} />
      <Route path="/reimbursement/:id" component={Reimbursement} />
      <Route path="/reimbursement" component={Reimbursement} />
      <Route path="/approve-reimbursement/:id" component={props => <Reimbursement approveMode={true} {...props} />} />
      <Route path="/reimbursements" component={Reimbursements} />
      <Route path="/user-reimbursements" component={UserReimbursements} />
      <Route path="/waiting-reimbursements" component={WaitingReimbursements} />
      <Route path="/test" component={Test} />
      <Route path="/particulars" component={Particulars} />
    </Switch>
  </BrowserRouter>
)

export default routes
