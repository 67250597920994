import React, { Component } from 'react'
import { toast } from 'react-toastify'
import Layout from '../../components/layout'
import { withContext } from '../../contexts/withContext'
import FormInput from '../../components/formInput'

class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      errors: {},
    }
  }

  submit = async e => {
    e.preventDefault()
    const { username, password } = this.state
    const res = await this.props.context.loginsecret(username)
    if (res.success) {
      this.props.history.push('/')
    } else {
      // TODO fields error too?
      toast.error('Incorrect username or password')
    }
  }

  render() {
    const { username, password, errors } = this.state
    return (
      <Layout>
        <form onSubmit={this.submit}>
          <FormInput
            fieldName="email"
            value={username}
            errors={errors.username}
            type="text"
            onChange={v => this.setState({ username: v })}
          />
          <button className="btn btn-primary" type="submit">
            Login
          </button>
        </form>
      </Layout>
    )
  }
}

export default withContext(LoginPage)
