import _ from 'lodash'
import React, { Component } from 'react'
import { toast } from 'react-toastify'
import FormInput from '../../components/formInput'
import Layout from '../../components/layout'
import { withContext } from '../../contexts/withContext'
import { replaceAll, formatRunningNumber } from '../../utils/utilFunctions'
import moment from 'moment'

class ReimbursementView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: {
        title: '',
        particulars: [
          {
            name: '',
            amount: '',
            category: '',
          },
        ],
        transfer_method: '',
        account_name: '',
        account_number: '',
        bank_name: '',
        selected_approver1: '',
        files: [],
        files_data: [], // before POST/PUT this will be deleted & replaced with files: string[] (id) instead
      },
      errors: {},
      particularCategories: [],
      approvers: [],
      dataFetched: false,
    }
    this.fileInputRef = React.createRef()
  }

  async componentDidMount() {
    const filter = new URLSearchParams(window.location.search).get('filter')

    const res = await this.props.context.fetch('get', '/api/particular-categories/')
    if (res.success) {
      this.setState({
        particularCategories: res.data.map(d => ({ value: d.id, label: d.name })),
      })
    } else {
      toast.error('Fail to fetch particular category list')
    }

    const reimId = this.props.match.params.id
    // console.log(reimId)
    if (reimId !== undefined) {
      // console.log('f')
      const reimRes = await this.props.context.fetch(
        'get',
        `/api/reimbursements/${reimId}/${filter ? `?filter=${filter}` : ''}${
          this.props.approveMode ? `?filter=waiting` : ''
        }`
      )
      // console.log('reimres',reimRes)
      if (reimRes.success) {
        this.setState({ fields: reimRes.data, dataFetched: true })
      } else {
        toast.error('Fail to fetch reimbursement')
      }
    }
    this.fetchApprovers()
  }

  fetchApprovers = async () => {
    const particularCatIdList = this.state.fields.particulars
      .filter(pc => pc.category !== undefined && pc.category !== '' && pc.category !== null)
      .map(pc => pc.category)
    // console.log('fetchapprovers', particularCatIdList.join(','))

    const profileRes = await this.props.context.fetch(
      'get',
      `/api/users/?filter=get_approvers&particular_categories=${particularCatIdList.join(',')}`
    )

    if (profileRes.success) {
      this.setState({ approvers: profileRes.data.map(d => ({ value: d.id, label: `${d.first_name} ${d.last_name}` })) })
    } else {
      toast.error('Fail to fetch available approvers')
    }
  }

  submit = async e => {
    e.preventDefault()
    const filter = new URLSearchParams(window.location.search).get('filter')
    const reimId = this.props.match.params.id

    const newFields = _.cloneDeep(this.state.fields)
    newFields['files'] = newFields['files_data'].map(fd => fd.id)
    delete newFields['files_data']

    // console.log(newFields)
    // return
    const res = await this.props.context.fetch(
      reimId ? 'put' : 'post',
      `/api/reimbursements/${reimId ? `${reimId}/${filter ? `?filter=${filter}` : ''}` : ''}`,
      { ...newFields, profile: undefined }
    )
    if (res.success) {
      toast.success(`Successfully ${reimId ? 'updated' : 'created'} reimbursement`)
      this.props.history.push('/user-reimbursements')
    } else {
      this.setState({ errors: res.data })
    }
  }

  approveReim = async (reimId, reimStatus) => {
    const res = await this.props.context.fetch('put', `/api/reimbursement-statuses/${reimId}/`, { status: reimStatus })
    if (res.success) {
      toast.success('Status updated')
      this.props.history.push('/waiting-reimbursements')
    } else {
      // console.log(res.data)
      if (res.data && res.data.status) {
        toast.error(`Update error: ${res.data.status}`)
      } else {
        toast.error(`Update error -`)
        //TODO generic alert
      }
    }
  }

  render() {
    const { fields, errors, particularCategories, approvers, dataFetched } = this.state
    const reimId = this.props.match.params.id

    if (reimId !== undefined && dataFetched === false) {
      return (
        <Layout>
          <div>Fetching data...</div>
        </Layout>
      )
    }
    return (
      <Layout>
        {fields.running_number ? (
          <div className="row mb-3">
            <div className="col-12 col-md-6">
              Running number: <b>{formatRunningNumber(fields.running_number)}</b>
            </div>
            <div className="col-12 col-md-6">
              Date: <b>{moment(fields.datetime).format('LLL')}</b>
            </div>
          </div>
        ) : null}
        <form onSubmit={this.submit}>
          <FormInput
            fieldName="Title (หัวข้อ)"
            colLabel={6}
            value={fields.title}
            errors={errors.title}
            type="text"
            onChange={v => this.setState({ fields: { ...fields, title: v } })}
          />
          {/* <div className="particular-row-wrapper"> */}
          <div>
            <div className="row particular-row">
              <div className="col-3">Category (ประเภท)</div>
              <div className="col-4">Description (คำอธิบาย)</div>
              <div className="col-3">Amount (จำนวนเงิน)</div>
            </div>

            {fields.particulars.map((p, idx) => (
              <div key={idx} className="row d-flex particular-row">
                <div className="col-3">
                  <FormInput
                    value={p.category}
                    errors={errors.particulars && errors.particulars[idx] && errors.particulars[idx].category}
                    choices={particularCategories}
                    onChange={v => {
                      const newParticulars = _.cloneDeep(fields.particulars)
                      newParticulars[idx].category = v
                      this.setState({ fields: { ...fields, particulars: newParticulars } }, () => this.fetchApprovers())
                    }}
                  />
                </div>
                <div className="col-4">
                  <FormInput
                    value={p.name}
                    errors={errors.particulars && errors.particulars[idx] && errors.particulars[idx].name}
                    type="text"
                    onChange={v => {
                      const newParticulars = _.cloneDeep(fields.particulars)
                      newParticulars[idx].name = v
                      this.setState({ fields: { ...fields, particulars: newParticulars } })
                    }}
                  />
                </div>
                <div className="col-3">
                  <FormInput
                    value={p.amount}
                    errors={errors.particulars && errors.particulars[idx] && errors.particulars[idx].amount}
                    type="text"
                    cleaveJsOptions={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
                    onChange={v => {
                      v = replaceAll(v, ',', '')
                      const newParticulars = _.cloneDeep(fields.particulars)
                      newParticulars[idx].amount = v
                      this.setState({ fields: { ...fields, particulars: newParticulars } })
                    }}
                  />
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ marginBottom: '1rem', marginTop: 'auto' }}
                    onClick={() => {
                      const newParticulars = _.cloneDeep(fields.particulars)
                      newParticulars.splice(idx, 1)
                      this.setState({ fields: { ...fields, particulars: newParticulars } }, () => this.fetchApprovers())
                    }}
                  >
                    Delete row
                  </button>
                </div>
              </div>
            ))}
          </div>

          <button
            type="button"
            className="btn-sm btn-primary mb-3"
            onClick={() => {
              const newParticulars = _.cloneDeep(fields.particulars)
              newParticulars.push({
                name: '',
                amount: '',
                category: '',
              })
              this.setState({ fields: { ...fields, particulars: newParticulars } }, () => this.fetchApprovers())
            }}
          >
            Add row
          </button>
          <div className="row">
            {/* conroles */}
            <FormInput
              fieldName="Transfer Method (วิธีโอนเงิน)"
              className="col-12"
              value={fields.transfer_method}
              errors={errors.transfer_method}
              choices={[{ value: 0, label: 'Cash (เงินสด)' }, { value: 1, label: 'Transfer (โอนเข้าบัญชี)' }]}
              onChange={v => this.setState({ fields: { ...fields, transfer_method: v } })}
            />
            {fields.transfer_method === 1 ? (
              <FormInput
                fieldName="Account Name (ชื่อบัญชี)"
                className="col-12"
                value={fields.account_name}
                errors={errors.account_name}
                type="text"
                onChange={v => this.setState({ fields: { ...fields, account_name: v } })}
              />
            ) : null}
            {fields.transfer_method === 1 ? (
              <FormInput
                fieldName="Account Number (หมายเลยบัญชี)"
                className="col-12"
                value={fields.account_number}
                errors={errors.account_number}
                type="text"
                onChange={v => this.setState({ fields: { ...fields, account_number: v } })}
              />
            ) : null}
            {fields.transfer_method === 1 ? (
              <FormInput
                fieldName="Bank Name (ชื่อธนาคาร)"
                className="col-12"
                value={fields.bank_name}
                errors={errors.bank_name}
                type="text"
                onChange={v => this.setState({ fields: { ...fields, bank_name: v } })}
              />
            ) : null}
            <FormInput
              fieldName="Select approver (เลือกผู้อนุมัติ)"
              className="col-12"
              value={fields.selected_approver1}
              errors={errors.selected_approver1}
              choices={approvers}
              onChange={v => this.setState({ fields: { ...fields, selected_approver1: v } })}
            />
            <FormInput
              fieldName={'Receipt (ใบเสร็จ)'}
              className="col-12"
              errors={errors.files}
              type="file"
              accept="image/*"
              onChange={async () => {
                const file = this.fileInputRef.current.input.current.files[0]
                const formData = new FormData()
                if (file !== undefined) {
                  formData.append('url', file)
                  formData.append('name', file.name)
                  const res = await this.props.context.fetch('post', '/api/reimbursement-files/', formData, {
                    'Content-Type': 'multipart/form-data',
                  })
                  if (res.success) {
                    const newFileData = _.cloneDeep(fields.files_data)
                    newFileData.push(res.data)
                    this.setState({ fields: { ...fields, files_data: newFileData } })
                    this.fileInputRef.current.value = null
                  } else {
                    this.fileInputRef.current.value = null
                    this.setState({ hackKey: Math.random() })
                    toast.error('File upload error')
                  }
                }
              }}
              ref={this.fileInputRef}
            />
          </div>
          {fields.files_data && fields.files_data.length > 0
            ? fields.files_data.map((f, idx) => (
                <div key={f.id} className="mt-3 d-flex flex-column flex-md-row">
                  <img className="reim-image" src={f.url} />
                  <a className="ml-md-3" href={f.url}>
                    {f.name === '' ? 'unnamed file' : f.name}
                  </a>
                  <a
                    className="ml-md-3"
                    href="#"
                    onClick={e => {
                      e.preventDefault()
                      const newFileData = _.cloneDeep(fields.files_data)
                      newFileData.splice(idx, 1)
                      this.setState({ fields: { ...fields, files_data: newFileData } })
                      this.fileInputRef.current.value = null
                    }}
                  >
                    [delete]
                  </a>
                </div>
              ))
            : null}
          <div className="row p-4">
            {this.props.approveMode === true ? (
              <div>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => {
                    // console.log(fields)
                    // conroles
                    if (fields.status === 0 || fields.status === 1) {
                      this.approveReim(reimId, 2)
                    } else if (fields.status === 2) {
                      this.approveReim(reimId, 3)
                    } else {
                      toast.error(`Invalid status: ${fields.status}`)
                    }
                  }}
                >
                  Approve
                </button>
                <button
                  className="btn btn-primary ml-3"
                  type="button"
                  onClick={() => {
                    this.approveReim(reimId, 1)
                  }}
                >
                  Rework
                </button>
              </div>
            ) : (
              <button className="btn btn-primary" type="submit">
                {reimId ? 'Update reimbursement' : 'Create new reimbursement'}
              </button>
            )}
          </div>
        </form>
      </Layout>
    )
  }
}

export default withContext(ReimbursementView)
