import { CONST } from '../constants'
import _ from 'lodash'

export const downloadCSV = (objectList, fileName) => {
  if (objectList.length === 0) return
  const headers = []
  let content = ''
  const keysCount = Object.keys(objectList[0]).length
  Object.keys(objectList[0]).map((key, idx) => {
    content += `${sanitizeString(key)}${idx !== keysCount - 1 ? ',' : ''}`
    headers.push(key)
  })
  content += '\n'
  objectList.map((obj, objIdx) => {
    headers.map((h, idx) => {
      if (obj[h] === undefined) obj[h] = ''
      content += `${sanitizeString(obj[h])}${idx !== keysCount - 1 ? ',' : ''}`
    })
    content += '\n'
  })
  const hiddenElement = document.createElement('a')
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURIComponent(content)}`
  hiddenElement.target = '_blank'
  hiddenElement.download = fileName
  hiddenElement.click()
}

const sanitizeString = desc => {
  let itemDesc
  if (desc) {
    itemDesc = desc.replace(/(\r\n|\n|\r|\s+|\t|&nbsp;)/gm, ' ')
    itemDesc = itemDesc.replace(/"/g, '""')
  } else {
    itemDesc = ''
  }
  return itemDesc.includes(',') ? `"${itemDesc}"` : itemDesc
}

export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, 'g'), replace)
}

// pretty status (Unnapproved, waiting at ${NAME}, etc)
// conroles
export const formatReimstatus = reimObject => {
  if (_.isEmpty(reimObject)) {
    return ''
  }
  if (reimObject.status !== 3 && reimObject.status !== 2 && !_.isEmpty(reimObject.selected_approver1_data)) {
    const approver_name = `${reimObject.selected_approver1_data.first_name} ${
      reimObject.selected_approver1_data.last_name
    }`
    return `${CONST.reimbursementStatuses.find(e => e.value === reimObject.status).name} (waiting @ ${approver_name})`
  } else {
    return CONST.reimbursementStatuses.find(e => e.value === reimObject.status).name
  }
  return ''
}

export const formatRunningNumber = runningNumber => {
  let appendzero
  if (runningNumber < 10) {
    appendzero = '000'
  } else if (runningNumber < 100) {
    appendzero = '00'
  } else if (runningNumber < 1000) {
    appendzero = '0'
  } else {
    appendzero = ''
  }
  return `SOP${appendzero}${runningNumber}`
}

export const getDays = () => {
  return new URL(window.location.href).searchParams.get('days')
}
