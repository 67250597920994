import React, { Component } from 'react'
import moment from 'moment'
import 'react-table/react-table.css'
import { toast } from 'react-toastify'
import Layout from '../../components/layout'
import { withContext } from '../../contexts/withContext'
import { CONST } from '../../constants'
import ReactTableSaveWidth from '../../components/ReactTableSaveWidth'
import { downloadCSV, formatReimstatus, formatRunningNumber, getDays } from '../../utils/utilFunctions'
import DayFilter from '../../components/dayFilter'

class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reimbursementList: [],
      dataFetched: false,
    }
  }

  async componentDidMount() {
    const res = await this.props.context.fetch('get', `/api/reimbursements/?filter=all&days=${getDays()}`)
    if (res.success) {
      this.setState({ reimbursementList: res.data, dataFetched: true })
    } else {
      toast.error('Fail to fetch reimbursement list')
    }
  }

  onDownloadCSV = () => {
    const data = this.state.reimbursementList.map(reim => {
      return {
        running_number: formatRunningNumber(reim.running_number),
        date: `${moment(reim.datetime).format('LLL')}`,
        name: `${reim.profile.first_name} ${reim.profile.last_name}`,
        phone: `${reim.profile.phone_number}`,
        approver1:
          reim.last_approver1 && `${reim.last_approver1.profile.first_name} ${reim.last_approver1.profile.last_name}`,
        approved1: reim.last_approver1 && reim.last_approver1.status !== 1 ? 'Yes' : 'No',
        approver1date: reim.last_approver1 && `${moment(reim.last_approver1.datetime).format('LLL')}`,
        approver2:
          reim.last_approver2 && `${reim.last_approver2.profile.first_name} ${reim.last_approver2.profile.last_name}`,
        approved2: reim.last_approver2 && reim.last_approver2.status !== 1 ? 'Yes' : 'No',
        approver2date: reim.last_approver2 && `${moment(reim.last_approver2.datetime).format('LLL')}`,
        title: reim.title,
        status: formatReimstatus(reim),
        amount: reim.particulars.reduce((a, b) => a + b.amount, 0).toString(),
        transfer: CONST.transferMethod.find(e => e.value === reim.transfer_method).name,
        bank_name: reim.bank_name,
        account_name: reim.account_name,
        account_number: reim.account_number,
      }
    })
    downloadCSV(data, 'transactions.csv')
  }

  render() {
    const { reimbursementList, dataFetched } = this.state

    if (dataFetched === false) {
      return (
        <Layout>
          <div>Fetching data...</div>
        </Layout>
      )
    }
    return (
      <Layout>
        <ReactTableSaveWidth
          storeName="allReim"
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (rowInfo) {
                  this.props.history.push(`/reimbursement/${rowInfo.original.id}?filter=all`)
                }
                if (handleOriginal) {
                  handleOriginal()
                }
              },
            }
          }}
          data={reimbursementList}
          columns={[
            {
              Header: 'Running Number',
              id: 'runningNumber',
              accessor: d => formatRunningNumber(d.running_number),
            },
            {
              Header: 'Date',
              id: 'datetime',
              accessor: d => d.datetime,
              Cell: c => <span>{c.original.datetime && moment(c.original.datetime).format('LLL')}</span>,
            },
            {
              Header: 'Name',
              id: 'name',
              accessor: d => `${d.profile.first_name}_${d.profile.last_name}`,
            },
            {
              Header: 'Phone',
              id: 'phone',
              accessor: d => d.profile.phone_number,
            },
            // conroles
            {
              Header: 'Approver1',
              id: 'Approver1',
              accessor: d =>
                d.last_approver1 && `${d.last_approver1.profile.first_name} ${d.last_approver1.profile.last_name}`,
            },
            {
              Header: 'Approved1',
              id: 'Approver1approved',
              accessor: d => (d.last_approver1 && d.last_approver1.status !== 1 ? 'Yes' : 'No'),
            },
            {
              Header: 'Approver1Date',
              id: 'Approver1Date',
              accessor: d => d.last_approver1 && d.last_approver1.datetime,
              Cell: c => (
                <span>{c.original.last_approver1 && moment(c.original.last_approver1.datetime).format('LLL')}</span>
              ),
            },
            {
              Header: 'Approver2',
              id: 'Approver2',
              accessor: d =>
                d.last_approver2 && `${d.last_approver2.profile.first_name} ${d.last_approver2.profile.last_name}`,
            },
            {
              Header: 'Approved2',
              id: 'Approver2approved',
              accessor: d => (d.last_approver2 && d.last_approver2.status !== 1 ? 'Yes' : 'No'),
            },
            {
              Header: 'Approver2Date',
              id: 'Approver2Date',
              accessor: d => d.last_approver2 && d.last_approver2.datetime,
              Cell: c => (
                <span>{c.original.last_approver2 && moment(c.original.last_approver2.datetime).format('LLL')}</span>
              ),
            },
            {
              Header: 'Title',
              id: 'title',
              accessor: d => d.title,
            },
            {
              Header: 'Status',
              id: 'status',
              accessor: d => formatReimstatus(d),
            },
            {
              Header: 'Amount',
              id: 'amount',
              accessor: d => d.particulars.reduce((a, b) => a + b.amount, 0),
            },
            {
              Header: 'Transfer',
              id: 'transfer',
              accessor: d => CONST.transferMethod.find(e => e.value === d.transfer_method).name,
            },
            {
              Header: 'Bank Name',
              id: 'bankName',
              accessor: d => d.bank_name,
            },
            // conroles
            {
              Header: 'Account Name',
              id: 'accName',
              accessor: d => d.account_name,
            },
            {
              Header: 'Account no.',
              id: 'accNo',
              accessor: d => d.account_number,
            },
          ]}
          className="-striped -highlight"
        />
        <button className="btn" type="button" onClick={this.onDownloadCSV}>
          Download CSV
        </button>
        <DayFilter />
      </Layout>
    )
  }
}

export default withContext(LoginPage)
