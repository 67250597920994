import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'
import 'react-table/react-table.css'
import { toast } from 'react-toastify'

import Layout from '../../components/layout'
import { withContext } from '../../contexts/withContext'
import { CONST } from '../../constants'
import ReactTableSaveWidth from '../../components/ReactTableSaveWidth'
import { formatReimstatus, formatRunningNumber } from '../../utils/utilFunctions'

class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reimbursementList: [],
      dataFetched: false,
    }
  }

  async componentDidMount() {
    const res = await this.props.context.fetch('get', '/api/reimbursements/')
    if (res.success) {
      this.setState({ reimbursementList: res.data, dataFetched: true })
    } else {
      toast.error('Fail to fetch reimbursement list')
    }
  }

  onDeleteClick = async id => {
    const res = await this.props.context.fetch('delete', `/api/reimbursements/${id}/`)
    if (res.success) {
      toast.success('Reimbursement deleted')
      const newReimbursementList = this.state.reimbursementList.filter(d => d.id !== id)
      this.setState({ reimbursementList: newReimbursementList })
    } else {
      toast.error('delete error')
    }
  }

  render() {
    const { reimbursementList, dataFetched } = this.state

    if (dataFetched === false) {
      return (
        <Layout>
          <div>Fetching data...</div>
        </Layout>
      )
    }
    return (
      <Layout>
        <ReactTableSaveWidth
          storeName="userReim"
          data={reimbursementList}
          columns={[
            {
              Header: 'Running Number',
              id: 'runningNumber',
              accessor: d => formatRunningNumber(d.running_number),
            },
            {
              Header: 'Date',
              id: 'datetime',
              accessor: d => d.datetime,
              Cell: c => <span>{c.original.datetime && moment(c.original.datetime).format('LLL')}</span>,
            },
            {
              Header: 'Title',
              id: 'title',
              accessor: d => d.title,
            },
            {
              Header: 'Status',
              id: 'status',
              accessor: d => formatReimstatus(d),
            },
            {
              Header: 'Action',
              id: 'edit',
              accessor: d => d,
              Cell: ({ value }) => {
                // conroles
                return (
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <button type="button" onClick={() => this.props.history.push(`/reimbursement/${value.id}`)}>
                      View
                    </button>
                    {value.status === 0 || value.status === 1 ? (
                      <button type="button" onClick={() => this.props.history.push(`/reimbursement/${value.id}`)}>
                        Edit
                      </button>
                    ) : null}
                    {value.status === 0 || value.status === 1 ? (
                      <button type="button" onClick={() => this.onDeleteClick(value.id)}>
                        Delete
                      </button>
                    ) : null}
                  </div>
                )
              },
            },
            {
              Header: '',
              id: 'pad',
              Cell: () => {
                // conroles
                return <div />
              },
            },
          ]}
          className="-striped -highlight"
        />
      </Layout>
    )
  }
}

export default withContext(LoginPage)
