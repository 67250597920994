export const CONST = {
  roles: [{ value: 0, name: 'User' }, { value: 1, name: 'Approver' }, { value: 2, name: 'Treasurer' }],
  reimbursementStatuses: [
    { value: 0, name: 'Unapproved' },
    { value: 1, name: 'Rework' },
    { value: 2, name: 'Approver approved' },
    { value: 3, name: 'Treasurer approved' },
  ],
  transferMethod: [{ value: 0, name: 'Cash' }, { value: 1, name: 'Transfer' }],
}

// TODO fix duplicate constants.json on project root ( need to dupe here because react can't import from outside /src )
