import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class DayFilter extends Component {
  render() {
    var url = new URL (window.location.href);
    var days = url.searchParams.get('days');
    
    if (!days || Number.isInteger(days)) {
        return null
    }

    url.searchParams.delete('days');



    return (
      <div className='d-flex'>
        <div className='mr-2'>Showing entries in the past {days} days</div>
        <a href={url.toString()}>Show all</a>
      </div>
    )
  }
}

export default DayFilter
