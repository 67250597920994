import React, { Component } from 'react'
import Swal from 'sweetalert2'
import moment from 'moment'
import 'react-table/react-table.css'
import { toast } from 'react-toastify'

import Layout from '../../components/layout'
import { withContext } from '../../contexts/withContext'
import { CONST } from '../../constants'
import ReactTableSaveWidth from '../../components/ReactTableSaveWidth'
import { formatReimstatus, formatRunningNumber } from '../../utils/utilFunctions'

class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reimbursementList: [],
      dataFetched: false
    }
  }

  async componentDidMount() {
    const res = await this.props.context.fetch('get', '/api/reimbursements/?filter=waiting')
    if (res.success) {
      this.setState({ reimbursementList: res.data, dataFetched: true })
    } else {
      toast.error('Fail to fetch reimbursement list')
    }
  }

  approveReim = async (reimId, reimStatus) => {
    const res = await this.props.context.fetch('put', `/api/reimbursement-statuses/${reimId}/`, { status: reimStatus })
    if (res.success) {
      toast.success('Status updated')
      this.setState({ reimbursementList: this.state.reimbursementList.filter(r => r.id !== reimId) })
    } else {
      if (res.data && res.data.error) {
        toast.error(`Update error: ${res.data.error.status}`)
      } else {
        //TODO generic alert
      }
    }
  }

  render() {
    const { reimbursementList, dataFetched} = this.state
    const { userData } = this.props.context

    if (dataFetched === false) {
      return (
        <Layout>
          <div>Fetching data...</div>
        </Layout>
      )
    }
    return (
      <Layout>
        <ReactTableSaveWidth
          storeName="waitingReim"
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                if (!rowInfo) return
                this.props.history.push(`/approve-reimbursement/${rowInfo.original.id}`)
              },
            }
          }}
          data={reimbursementList}
          columns={[
            {
              Header: 'Running Number',
              id: 'runningNumber',
              accessor: d => formatRunningNumber(d.running_number),
            },
            {
              Header: 'Date',
              id: 'datetime',
              accessor: d => d.datetime,
              Cell: c => <span>{c.original.datetime && moment(c.original.datetime).format('LLL')}</span>,
            },
            {
              Header: 'Name',
              id: 'name',
              accessor: d => `${d.profile.first_name}_${d.profile.last_name}`,
            },
            {
              Header: 'Phone',
              id: 'phone',
              accessor: d => d.profile.phone_number,
            },
            {
              Header: 'Title',
              id: 'title',
              accessor: d => d.title,
            },
            {
              Header: 'Status',
              id: 'status',
              accessor: d => formatReimstatus(d),
            },
            {
              Header: 'Amount',
              id: 'amount',
              accessor: d =>
                d.particulars.reduce((a, b) => {
                  return a + b.amount
                }, 0),
            },
          ]}
          className="-striped -highlight"
          showPagination={true}
        />
      </Layout>
    )
  }
}

export default withContext(LoginPage)
