import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withContext } from '../../contexts/withContext'
import { CONST } from '../../constants/index'

class Navbar extends Component {
  render() {
    const { userData } = this.props.context
    // conroles
    const roleName = userData ? CONST.roles.filter(e => e.value === userData.role)[0].name : ''

    return (
      <div className="w-100 bg-dark text-white p-3 d-flex align-items-center navbar">
        <Link to="/">Home</Link>
        <div className="ml-auto">
          {userData ? (
            <div>
              Logged in as {userData.email} [role={roleName}] (
              <button type="button" style={{ border: 'none' }} onClick={this.props.context.logout}>
                Logout
              </button>
              ,<Link to="/editprofile">Edit user</Link>)
            </div>
          ) : (
            'Not logged in'
          )}
        </div>
      </div>
    )
  }
}

export default withContext(Navbar)
